/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React from "react";
import { Helmet } from "react-helmet";
const faqs = [
  {
    question: "What is payroll, and how does it work?",
    answer:
      "Payroll is the act of paying employees of a company in compliance with tax laws and regulations. A high end payroll provider ensures financial accuracy as well as ensuring that your employees are paid on time, taxes are submitted to the CRA on time and T4’s are filed and provided to employees on time. A high end payroll provider will also be able to include all payroll details onto your company’s books.",
  },
  {
    question: "What should a small business owner know about bookkeeping?",
    answer:
      "A small business owner should understand that bookkeeping is the backbone of every well-functioning, profitable business. All operations of the business must take bookkeeping into consideration as creating efficient workflows is what ultimately leads to higher profits. It is crucial for every business to have their bookkeeping and workflow operations under full control in order to be able to scale effectively.",
  },
  {
    question: "What are small businesses looking for in Bookkeeping software?",
    answer:
      "Small businesses should use cloud based bookkeeping software such as Quickbooks Online in order to effectively run their business’s financial operations.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
            "name": "What is payroll, and how does it work?",
            "acceptedAnswer": {
              "@type": "Answer",
            "text": "<p>Payroll is the act of paying employees of a company in compliance with tax laws and regulations. A high end payroll provider ensures financial accuracy as well as ensuring that your employees are paid on time, taxes are submitted to the CRA on time and T4’s are filed and provided to employees on time. A high end payroll provider will also be able to include all payroll details onto your company’s books.</p>"
                  }
                }, {
            "@type": "Question",
              "name": "What should a small business owner know about bookkeeping?",
                "acceptedAnswer": {
              "@type": "Answer",
                "text": "<p>A small business owner should understand that bookkeeping is the backbone of every well-functioning, profitable business. All operations of the business must take bookkeeping into consideration as creating efficient workflows is what ultimately leads to higher profits. It is crucial for every business to have their bookkeeping and workflow operations under full control in order to be able to scale effectively.<p>"
             }
           },{
            "@type": "Question",
              "name": "What are small businesses looking for in Bookkeeping software?",
                "acceptedAnswer": {
              "@type": "Answer",
                "text": "<p>Small businesses should use cloud based bookkeeping software such as Quickbooks Online in order to effectively run their business’s financial operations.<p>"
             } 
          }]
              }`}
        </script>
      </Helmet>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
