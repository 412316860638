import React from "react";

import CTA from "./CTA";
import Link from "gatsby-link";

export default function Example() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-theme font-semibold tracking-wide uppercase">
              Certus
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Bookkeeping Mississauga
            </span>
          </h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Certus Bookkeeping provides industry-leading bookkeeping services in
            Mississauga for both business owners and individuals. We understand
            the burden that comes with managing your own finances. This is why
            our primary objective is to relieve you of this stress. The Certus
            team is constantly striving to improve the work-life balance of our
            clients through our gold standard of service.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            You need to have a team you can trust managing your finances. Certus
            is a trusted source of professional bookkeeping services with years
            of experience providing high-quality accounting and bookkeeping
            services across the GTA.
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>Mississauga Services</h2>
          <p>
            From our initial consultation, we will analyze your business’s
            financial needs and develop a solution that will save you time and
            money. Our goal is to take the stress out of tax fillings, knowing
            your professional team of bookkeepers has your business’s finances
            under control.
          </p>
          <p>
            Operating a small to medium-sized business takes a lot of time and
            energy. This is further pronounced when tasked with the
            responsibility of managing all of the company's finances on your
            own. This can take away from your ability to focus on building your
            business. By allowing Certus to take control of your financial
            operations, you will be able to focus on what you do best.
          </p>
          <p>
            Finding the right provider of professional bookkeeping services will
            produce significant savings. It will help you discover new income
            streams, prevent cash flow crunches, and have all your necessary tax
            preparation completed. Any savings you create can be reinvested back
            into your business.
          </p>
        </div>
        <CTA />
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            We are also a leading provider of tax services for individuals. The
            Certus team is here to help you with any of your day-to-day
            financial planning. Some of our services include receipt
            digitization & filing, tax planning, expense tracking, and financial
            reporting.
          </p>
          <h2 style={{ marginTop: "0px" }}>Get Started Today</h2>
          <p>
            Each client is unique, which is why we custom tailor our services to
            meet your individual needs. Whether it is payroll services for small
            businesses or tax services for individuals, we will always hold
            ourselves to the highest standard.
          </p>
          <p>
            Interested in receiving a quote? Visit the{" "}
            <Link
              to="/contact-us"
              style={{
                textDecoration: "none",
                color: "#6B7280",
                "&:hover": {
                  background: "#efefef",
                },
                ":visited": {
                  color: "purple",
                },
              }}
            >
              contact us{" "}
            </Link>{" "}
            page on our website to schedule a consultation with one of our
            financial experts. We are confident that after your free
            consultation you will see that the Certus team is the one for you.
          </p>
        </div>
      </div>
    </div>
  );
}
