import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title="Bookkeeping Mississauga | Certus Bookkeeping"
      link rel="canonical" href="https://certusbookkeeping.com/bookkeeping-mississauga"
      meta={[
        {
          name: `description`,
          content:
            "Certus Bookkeeping is a professional bookkeeping service located in the heart of Mississauga. Our team provides high quality financial services to small and medium businesses as well as individuals.",
        },
        {
          property: `og:title`,
          content: "Bookkeeping Mississauga",
        },
        {
          property: `og:description`,
          content:
            "Certus Bookkeeping is a professional bookkeeping service located in the heart of Mississauga. Our team provides high quality financial services to small and medium businesses as well as individuals.",
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    />
  );

}
